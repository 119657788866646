import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PopupBox from "./PopupBox";
import PopupSelectField from "./ui-el/PopupSelectField";
import { onSelectData2, serviceFinder, titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";


function PreferenceRegistryPopup(props) {
    const preferencesCtx = useSelector(state => state.stepSlice.data.preferenze);

    const [_, setSelection] = useState("");

    const [data, setData] = useState(props?.registryData.preferenze ?? preferencesCtx);

    let serviceName = serviceFinder(props?.service);

    const classChange = (e) => {
        setData(prev => {
            const newObj = { ...prev };

            const internal = { ...newObj[serviceName] };
            internal.classe = e.target.value;

            newObj[serviceName] = internal;
            return newObj;
        })
    }

    const edit = () => {
        props.update({
            id: "Preferenze",
            data: data
        });

        props.close();
    }

    const onChange = (e, key, title, isLinear = false) => {
        // console.log("on change", key, title, isLinear);
        setData(prev => {
            const obj = structuredClone(prev);
            if (!isLinear) {
                obj[serviceName][key][title] = e.target.value;
            }
            else {
                obj[serviceName][title] = e.target.value;
            }

            return { ...prev, ...obj }
        })
    }

    const [shouldClearIndirizzo, setShouldClearIndirizzo] = useState(false);
    const [shouldClearPEC, setShouldClearPEC] = useState(false);

    useEffect(() => {
        if (shouldClearIndirizzo) {
            setData(prevData => ({
                ...prevData,
                [serviceName]: {
                    ...prevData[serviceName],
                    indirizzo_spedizione: ""
                }
            }));
            setShouldClearIndirizzo(false);
        }
    }, [shouldClearIndirizzo, serviceName]);

    // useEffect(() => {
    //     console.log("PreferenceRegistryPopup", props?.registryData)
    //     setData(props?.registryData.preferenze ?? preferencesCtx)
    // }, [props?.registryData])   
    // useEffect(() => {
    //     if (shouldClearPEC) {
    //         setData(prevData => ({
    //             ...prevData,
    //             [serviceName]: {
    //                 ...prevData[serviceName],
    //                 indirizzo_pec: ""
    //             }
    //         }));
    //         setShouldClearPEC(false);
    //     }
    // }, [shouldClearPEC, serviceName]);

    // console.log("///////////////////// preference popup", data);

    const render = () => {
        return <PopupBox
            close={props.close}
            save={edit}
            title="Preferenze">
            {
                Object.keys(data[serviceName]).map((key, idx) => {

                    // Verifica se la modalità di ritiro è "Invio tramite raccomandata"
                    const isModalitaRaccomandata = data[serviceName]?.modalita === "Invio tramite raccomandata";
                    const isSendByPEC = data[serviceName]?.modalita === "Invio tramite PEC";
                    const showMarca = data[serviceName]?.chiede_extra === "di estrarre copia conforme (necessita di marca da bollo da 16,00 euro)"
                    // Verifica se l'invalidità è "Permanente"
                    const isPermanente = Array.isArray(data[serviceName]?.tipo_di_invalidita) ? data[serviceName]?.tipo_di_invalidita?.[0]?.toLowerCase() === "permanente" : data[serviceName]?.tipo_di_invalidita?.toLowerCase() === "permanente";

                    // Se l'invalidità è permanente, non mostrare i campi relativi a "Marca da bollo"
                    if (isPermanente && key.includes("marca")) {
                        return null;
                    }

                    // console.log("/////////////// options /////////////// ", key, isSendByPEC, isModalitaRaccomandata)
                    
                    // Verifica per escludere "Indirizzo di spedizione" se la modalità non è "Invio tramite raccomandata"
                    if (key === "indirizzo_spedizione" && !isModalitaRaccomandata) {
                        // if (!shouldClearIndirizzo) {
                        //     setShouldClearIndirizzo(true);
                        // }
                        // console.log("render preferences", key, data[serviceName], isModalitaRaccomandata)
                        return null; // Non mostrare "Indirizzo di spedizione"
                    }

                    if (key === "indirizzo_pec" && !isSendByPEC) {
                        // if (!shouldClearPEC) {
                        //     setShouldClearPEC(true);
                        // }
                        return null; // Non mostrare "Indirizzo di pec"
                    }

                    if(key === "marca_bollo" && !showMarca){
                        return null;
                    }

                    let optionsByKey = props?.choiceData?.find(itm => itm?.id === key);
                    // if(optionsByKey?.[0]?.internalId){
                    //     optionsByKey = props?.choiceData?.filter(itm => itm?.id === key);
                    // }
                    const options = optionsByKey ? [optionsByKey] : props?.choiceData?.filter(itm => {
                        if (key?.includes(titleNormalizer(itm?.title?.[1] || "").normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                            return true;
                        else
                            return false;
                    });

                    console.log("/////////////// options /////////////// ", key, optionsByKey, options)
                    
                    // console.log("isisPermanente", isPermanente, key, data[serviceName][key])



                    if (key === "classe" && data[serviceName][key] !== "" && (props.service === "RMS" || props.service === "RTS")) {
                        return <PopupTextField
                            defaultValue={data[serviceName][key] ? data[serviceName][key] : preferencesCtx[key]}
                            onBlur={classChange}
                            id={key}
                            fieldTitle={key}
                            key={uniqueIdBuilder()} />
                    }

                    if (options.length > 0) {
                        let values = [];

                        for (let i of options) {
                            values = i.options.map(itm => itm.value);


                            if (i.type === "MultiText") {
                                // console.log("xxxxxxxxxxxxxxxxxxx",);

                                return i.options.map(el => {
                                    const normalizedTitle = titleNormalizer(el.title).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                                    //child title gotta be 0 if you want the property treated like a first level
                                    if (i?.child_title) {
                                        if (i.child_title.length === 0) {
                                            const linears = ["indirizzo_spedizione", "indirizzo_pec", "motivo", "vecchio_contrassegno", "m_data", "numero_1", "data_marca_1", "numero_2", "data_marca_2"];
                                            // console.log("here", key);

                                            if (i.title[1] === "M DATA") {
                                                return <PopupTextField
                                                    fieldType="date"
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={"Data del matrimonio (presumibile)"}
                                                    key={normalizedTitle} />
                                            }

                                            // console.log("")
                                            // if(key === "marca")
                                            if(el?.useId && el?.id && data[serviceName][key]?.[el?.id]){
                                                return <PopupTextField
                                                    defaultValue={data[serviceName][key]?.[el?.id]}
                                                    onChange={(e) => { onChange(e, key, el?.id, false) }}
                                                    id={el?.id}
                                                    fieldType={el?.type || "text"}
                                                    fieldTitle={el.title}
                                                    key={el?.id}
                                                    max={el?.max || null}
                                                    regex={el?.regex || ""}
                                                />
                                            }
                                                // console.log("*************** render value *************** ", key, el, data[serviceName][key]?.[el?.id])

                                            // //test marca da bollo
                                            if (key.includes("numero")) {
                                                return <PopupTextField
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={el.title}
                                                    key={normalizedTitle}
                                                    max="14"
                                                    regex="^[0-9]{14}$"
                                                />
                                            } else if (key.includes("data")) {
                                                return <PopupTextField
                                                    fieldType="date"
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={el.title}
                                                    key={normalizedTitle}
                                                    min={new Date().toISOString().split('T')[0]}
                                                />
                                            }



                                            return <PopupTextField
                                                defaultValue={data[serviceName][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle} />
                                        }
                                    }

                                    //test marca da bollo
                                    if (!isPermanente && key.includes("marca")) {
                                        // console.log("xxxxxxxxxxxxxxxxxxx", key);

                                        if (el.title.toLowerCase() === "numero") {
                                            return <PopupTextField
                                                defaultValue={data[serviceName][key][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle}
                                                max="14"
                                                regex="^[0-9]{14}$"
                                            />
                                        } else if (el.title.toLowerCase() === "data") {
                                            return <PopupTextField
                                                fieldType="date"
                                                defaultValue={data[serviceName][key][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle}
                                                min={new Date().toISOString().split('T')[0]}
                                            />
                                        }
                                    }
                                    //fine test marca da bollo

                                    return <PopupTextField
                                        defaultValue={data[serviceName][key][normalizedTitle]}
                                        onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                        id={titleNormalizer(normalizedTitle)}
                                        fieldTitle={el.title}
                                        key={normalizedTitle} />
                                })
                            }
                        }

                        if (!Array.isArray(data[serviceName][key])) {
                            return <PopupSelectField
                                defaultValue={data[serviceName][key] ? data[serviceName][key] : preferencesCtx[serviceName][key]}
                                onChange={(e) => { onSelectData2(e, serviceName, setData, setSelection) }}
                                id={key}
                                fieldTitle={titleExtractor(key)}
                                options={values}
                                description="Seleziona la preferenza"
                                key={uniqueIdBuilder()} />

                        } else {
                            if (props.service === "PPI") {

                                return <PopupSelectField
                                    defaultValue={data?.[serviceName]?.[key]?.[0] || (preferencesCtx?.[serviceName]?.[key] ? preferencesCtx?.[serviceName]?.[key]?.[0] : "")}
                                    onChange={(e) => { onSelectData2(e, serviceName, setData, setSelection) }}
                                    id={key}
                                    fieldTitle={titleExtractor(key)}
                                    options={values}
                                    description="Seleziona la preferenza"
                                    key={uniqueIdBuilder()} />
                            }
                        }
                    }
                })
            }
        </PopupBox>
    }

    return (
        <>
            {render()}
        </>
    )
}

export default PreferenceRegistryPopup;