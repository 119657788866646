import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../context/StepsContext";
import { titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";

import "../global.css"

function SelectionFormBox(props) {
    const dispatcher = useDispatch();
    const title = Array.isArray(props?.title) ? props.title[1] : props.title;

    const classSet = (e) => {
        const id = titleNormalizer(e.target.parentNode.children[0].textContent);
        const titleTop = titleNormalizer(props?.title[0]);

        // dispatcher(stepSlice
        //     .actions
        //     .dynamicSave({id:props.oKey,topId: titleTop,internalId: id, data: e.target.value,remove: false,test: true}));

        props.update({
            id: "classe",
            data: e.target.value
        });
    }

    const onChange = (e) => {
        const data = e.target.options[e.target.selectedIndex].textContent;

        props.update({
            // id: titleNormalizer(props.title[1]),
            id: props?.id || titleNormalizer(title),
            selectionID: e.target.value,
            data: data
        });
    }

    return (
        <section className="it-page-section" id="school-choice">
            <div className="cmp-card mb-40">
                <div className={`card has-bkg-grey p-big p-4  ${props.disp === "disp" ? "box-remover" : ""}`}>
                    <div className={`card-header border-0 p-0 mb-lg-30`}>
                        <div className="d-flex">
                            <h1 className="title-xlarge mb-0">
                                {titleExtractor(props.showTitle)}
                            </h1>
                        </div>
                        <p className="subtitle-small mb-0">
                            {props.description}
                        </p>
                    </div>
                    <div className={`card-body p-0  ${props.disp === "disp" ? "box-enlighter" : ""}`}>
                        <div className="select-wrapper p-big p-4 select-partials">
                            <p style={{ display: "none" }}>{title}</p>
                            <label htmlFor="select-school" className="visually-hidden">
                                {props.selectTitle}
                            </label>

                            <select
                                id="select-school"
                                required onChange={onChange}
                                className={`${props.disabled ? "disabled" : ""}`}
                                disabled={props.disabled}
                            >
                                <option selected="selected" disabled>
                                    {props?.firstSelection ? props.firstSelection : "Seleziona un opzione"}
                                </option>
                                {
                                    Array.isArray(props?.choices) &&
                                    props.choices?.map((option, idx) => {
                                        if (props.defaultValue === option.value) {
                                            return (
                                                <option selected="selected" value={option.id} key={uniqueIdBuilder()}>
                                                    {option.value}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option value={option.id} key={uniqueIdBuilder()}>
                                                    {option.value}
                                                </option>
                                            )
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <br />
                    {
                        title === "SCUOLA" &&
                        <PopupTextField
                            id="classe"
                            defaultValue={props.classDefaultValue}
                            onChange={classSet}
                            fieldType="text"
                            fieldTitle="Classe"
                            description="Inserisci la classe*" />
                    }
                    {props.children}
                </div>
            </div>
        </section>
    )
}

export default SelectionFormBox;