import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import { useAccess } from "./access";
import { getTenantCode } from "../../../util";

function Summary(props) {
    const ctx = useSelector(state => state.stepSlice.data);

    // Stato locale per gestire i dati aggiornati
    const [localCtx, setLocalCtx] = useState(ctx);

    const {data, values, loading, error, isValidStep, getPrefs, update, updateField, updatePrefs, save, savePrefs} = useAccess(props.save, null, props.send);
    const onNext = async () => {
        save(true);
    };

    const tenantCode = getTenantCode();
    const shouldShowAddressField = true //data?.modalita === "Invio tramite raccomandata";
    const shouldShowPECField = true // data?.modalita === "Invio tramite PEC"
    const shouldShowMarca = tenantCode === "L337" && values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
    const applicant = data?.richiedente?.[0];
    const delegate = data?.delegato?.[0];

    // console.log("new ctx", ctx);
    return (
        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        {/* Loader per indicare il caricamento */}
                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                            <Loader loading={loading} />
                        </div>

                        {/* Componente SummaryCard per il richiedente */}
                        <SummaryCard
                            type="richiedente"
                            cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
                            mod={true}
                            update={update}
                            id={"Richiedente"}
                            taxID={applicant?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant}
                        >
                            <FormBoxField title="Tipo" value={applicant?.type.type} />
                            <FormBoxField title="Nome" value={applicant?.anagrafica?.nome} />
                            <FormBoxField title="Cognome" value={applicant?.anagrafica?.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica?.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant?.anagrafica?.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica?.luogo_di_nascita} />
                            <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi?.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant?.indirizzi?.domicilio} />
                            <FormBoxField title="Telefono" value={applicant?.contatti?.telefono} />
                            <FormBoxField title="Email" value={applicant?.contatti?.email} />
                            <FormBoxField title="Carta di identità" value={applicant?.documenti?.carta_di_identita} />
                            {applicant?.type.type === "Delegato" && <FormBoxField title="Delega" value={applicant?.documenti?.delega} />}

                        </SummaryCard>

                        {data?.delegato?.length > 0 &&
                            <SummaryCard
                                type="delegato"
                                cardTitle={`${delegate?.anagrafica?.nome} ${delegate?.anagrafica?.cognome}`}
                                mod={true}
                                update={update}
                                id={"Delegato"}
                                title={"Delegante"}
                                taxID={delegate?.anagrafica.codice_fiscale}
                                service={props.info.code}
                                registryData={delegate}
                            >
                                <FormBoxField title="Nome" value={delegate?.anagrafica?.nome} />
                                <FormBoxField title="Cognome" value={delegate?.anagrafica?.cognome} />
                                <FormBoxField title="Codice Fiscale" value={delegate?.anagrafica?.codice_fiscale} />
                                <FormBoxField title="Data di nascita" value={delegate?.anagrafica?.data_di_nascita} />
                                <FormBoxField title="Luogo di nascita" value={delegate?.anagrafica?.luogo_di_nascita} />
                                <FormBoxField title="Indirizzo residenza" value={delegate?.indirizzi?.residenza} />
                                <FormBoxField title="Comune residenza" value={delegate?.indirizzi?.domicilio} />
                                <FormBoxField title="Telefono" value={delegate?.contatti?.telefono} />
                                <FormBoxField title="Email" value={delegate?.contatti?.email} />
                                <FormBoxField title="Carta di identità" value={delegate?.documenti.carta_di_identita} />
                            </SummaryCard>
                        }

                        <SummaryCard
                            type="Preferenze"
                            mod={true}
                            cardTitle={`Preferenze di servizio`}
                            service="RAAT"
                            registryData={{
                                access: data
                            }}
                            choiceData={[
                                {
                                    "options": [
                                        { id: 1, value: "Richiesta di accesso civico generalizzato" },
                                        { id: 2, value: "Richiesta di accesso agli atti amministrativi" }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "TIPOLOGIA"
                                    ],
                                    "type": "Select"
                                },
                                {
                                    "options": values.modes || [],
                                    "title": [
                                        "ACCESSI",
                                        "MODALITA"
                                    ],
                                    "type": "Select"
                                },
                                ...(shouldShowAddressField && [
                                    {
                                        "options": [
                                            {
                                                "id": 1,
                                                "title": "Indirizzo spedizione"
                                            }
                                        ],
                                        "title": [
                                            "ACCESSI",
                                            "INDIRIZZO"
                                        ],
                                        "child_title": [],
                                        "type": "MultiText"
                                    }
                                ] || []),
                                ...(shouldShowPECField && [
                                    {
                                        "options": [
                                            {
                                                "title": "Indirizzo PEC"
                                            }
                                        ],
                                        "title": [
                                            "ACCESSI",
                                            "PEC"
                                        ],
                                        "child_title": [],
                                        "type": "MultiText",
                                        "id": "indirizzo_pec"
                                    }
                                ] || []),
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Documento"
                                        },
                                        {
                                            "id": 2,
                                            "title": "Informazione"
                                        },
                                        {
                                            "id": 3,
                                            "title": "Dato"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "CHIEDE"
                                    ],
                                    "child_title": [
                                        "Documento",
                                        "Informazione",
                                        "Dato"
                                    ],
                                    "id": "chiede",
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Motivo"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "MOTIVO"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": values?.extra_declarations || [],
                                    "title": [
                                        "ACCESSI",
                                        "CHIEDE"
                                    ],
                                    "type": "Select",
                                    "id": "chiede_extra",
                                },
                                {
                                    "options": [
                                        {
                                            "id": "numero",
                                            "title": "Numero marca",
                                            "regex": "^[0-9]{14}$",
                                            "max": "14",
                                            "useId": true
                                        },
                                        {
                                            "id": "data",
                                            "title": "Data marca",
                                            "useId": true,
                                            "type": "date"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "MARCA BOLLO"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText",
                                    "id": "marca_bollo"
                                }
                            ]}
                            update={updatePrefs}
                        >

                            <FormBoxField title="Tipologia" value={data?.tipologia || "Non compilato"} />
                            <FormBoxField title="Modalità di ritiro" value={data?.modalita || "Non compilato"} />
                            {data?.modalita === "Invio tramite raccomandata" && (
                                <FormBoxField title="Indirizzo di spedizione" value={data?.indirizzo_spedizione || "Non compilato"} />
                            )}
                            {data?.modalita === "Invio tramite PEC" && (
                                <>
                                    <FormBoxField title="Indirizzo PEC" value={data?.indirizzo_pec || "Non compilato"} />
                                    
                                
                                </>
                            )}
                            <FormBoxField title="Documento" value={data?.chiede.documento || "Non compilato"} />
                            <FormBoxField title="Informazione" value={data?.chiede.informazione || "Non compilato"} />
                            <FormBoxField title="Dato" value={data?.chiede.dato || "Non compilato"} />
                            <FormBoxField title="Motivo" value={data?.motivo || "Non compilato"} />
                            {tenantCode === "L337" && <FormBoxField title="Chiede" value={data?.chiede_extra || "Non compilato"} />}
                            {shouldShowMarca &&
                                <>
                                    <FormBoxField title="Numero marca da bollo" value={data?.marca_bollo?.numero || "Non compilato"} />
                                    <FormBoxField title="Data marca da bollo" value={data?.marca_bollo?.data || "Non compilato"} />
                                </>
                            }
                            {/* {data?.chiede_extra?.length > 0 &&
                                data?.chiede_extra.map(el => {
                                    return <FormBoxField title="Dichiaro" value={el?.indexOf("-") === 1 ? el?.slice(2) : el} key={el} />
                                })
                            } */}
                            {Array.isArray(data?.dichiaro) &&
                                data?.dichiaro.map(el => {
                                    return <FormBoxField title="Dichiaro" value={el?.indexOf("-") === 1 ? el?.slice(2) : el} key={el} />
                                })
                            }
                        </SummaryCard>

                        <NextBtnForm
                            send={onNext}
                            back={props.back}
                            saveRequest={save}
                            last={true}
                            noSave={false}
                        />
                        {props.saved && <SuccessfullPopup />}
                        {props.fail && <SuccessfullPopup isError={true} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary;
